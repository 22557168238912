import React, { useCallback, useEffect, useRef, useState } from "react"
import _get from "lodash/get"
import AuthContext from "../contexts/AuthContext"
import getFirebase, { database, firebaseAuth } from "../../firebaseConfig"
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
} from "firebase/auth"
import { STATUS, VIEWS } from "../constants/common"
import { onValue, ref } from "firebase/database"
import { isUserExistinDB } from "../utils/firebase.utils"

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, loggedInUser => {
      // console.log({ loggedInUser })
      if (loggedInUser && _get(loggedInUser, "emailVerified")) {
        // console.log({ loggedInUser })
        getUserFromuid(loggedInUser)
      }
      // else signOutUser()
    })

    return unsubscribe
  }, [])

  const loginUser = async (email, password) => {
    try {
      const user = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )

      // const userExists = await isUserExistinDB(email)
      // console.log({ user })
      if (!_get(user, "user.emailVerified")) {
        await sendEmailVerification(user.user)
        return {
          status: STATUS.SUCCESS,
          view: VIEWS.VERIFY_EMAIL,
          data: user,
          msg: "Please check your email. A verification link has been sent.",
          error: null,
        }
      }

      // setUser(user)
      getUserFromuid(user.user)
      return {
        status: STATUS.SUCCESS,
        data: user,
      }
    } catch (error) {
      console.log({ error })
      if (_get(error, "code") === "auth/user-not-found") {
        return {
          status: STATUS.FAILED,
          msg: "No user found with email. Please signup.",
        }
      }
      return {
        status: STATUS.FAILED,
        msg: "Something went wrong. Please try again.",
      }
    }
  }

  const signupUser = async (email, password) => {
    try {
      const auth = getAuth()
      const user = await createUserWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )

      const userExists = await isUserExistinDB(email)

      console.log({ user })
      if (!userExists) {
        if (!_get(user, "user.emailVerified")) {
          await sendEmailVerification(user.user)
          return {
            status: STATUS.SUCCESS,
            view: VIEWS.VERIFY_EMAIL,
            data: user,
            msg: "Please check your email. A verification link has been sent.",
            error: null,
          }
        }
      }

      setUser(user)
      return {
        status: STATUS.SUCCESS,
        data: user,
      }

      // getUserFromuid(user)
    } catch (error) {
      if (_get(error, "code") === "auth/email-already-in-use") {
        await signOutUser()
        return {
          status: STATUS.FAILED,
          msg: "User with this email already exists. Please login.",
        }
      }
      return {
        status: STATUS.FAILED,
        data: "Something went wrong, Please try again later.",
      }
    }
  }

  const getUserFromuid = user => {
    if (user?.uid) {
      const usersRef = ref(database, `users/${user.uid}`)
      onValue(usersRef, snapshot => {
        if (snapshot.exists()) {
          // console.log({ uid: user.uid, usersRef })
          // console.log({ userSnapshot: snapshot.val() })
          setUser({
            ...snapshot.val(),
            uid: user.uid,
          })
        } else {
          setUser({})
        }
      })
    }
    // database
    //   .object("/users/" + user.uid)
    //   .valueChanges()
    //   .get(value => {
    //     if (value != null) {
    //       setUser(value)
    //     }
    //   })
  }

  const signOutUser = async () => {
    await firebaseSignOut(firebaseAuth)
  }

  const sendResetPasswordMail = async email => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email)
      return {
        status: STATUS.SUCCESS,
        msg: "Email sent successfully to reset your password",
      }
    } catch (error) {
      console.log({ error })
      let msg = "Error sending email."

      if (_get(error, "code") === "auth/user-not-found") {
        msg = "User does not exist. Please signup"
      }
      return {
        status: STATUS.FAILED,
        msg,
      }
    }
  }

  const getAndSetuser = loggedInUser => {
    console.log("getAndSetuser", { loggedInUser })
    if (loggedInUser && _get(loggedInUser, "emailVerified")) {
      console.log({ loggedInUser })
      getUserFromuid(loggedInUser)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loginUser,
        signupUser,
        signOutUser,
        sendResetPasswordMail,
        setUser,
        getAndSetuser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
